<template>
    <v-card hover>
        <v-card-title style="background-color:#4E87DD; color:#FFFFFF; height:70px;">
            <v-row no-gutters>
                <v-col class="pt-1 pl-4 text-h5 font-weight-bold">비밀번호를 입력해 주세요</v-col>
            </v-row>
        </v-card-title>

        <v-card-text class="pb-0 pt-3">
            <v-row no-gutters>
                <v-col class="pb-0 title" style="color:#999999">아이디</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col class="pt-2 pb-5 title">{{ adminUserId }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col class="pt-0 pb-0 title" style="color:#999999">비밀번호</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col class="pt-2 pb-0">
                    <v-text-field type="password" outlined placeholder="비밀번호를 입력해 주세요" :error="formStatus['admin_user_password'].err" :ref="'admin_user_password'"
                                  v-model="formData.admin_user_password" @keydown.enter="submitPopup()">
                        <template v-slot:append v-if="formStatus['admin_user_password'].err_msg">
                            <div style="color:#FF4D4D; font-size:13px; padding-top:5px;">
                                {{ formStatus['admin_user_password'].err_msg }}
                            </div>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="ma-0 pa-0">
            <v-row no-gutters>
                <v-col cols="12" class="pr-4 pb-5 pt-2 text-right">
                    <v-btn width="130px" color="primary" text v-on:click="closePopup()"><span style="color:#555555; font-size:1.4em;">취소</span></v-btn>
                    <v-btn class="white--text" width="130px" color="#4573D5" large v-on:click="submitPopup()"><span style="font-size:1.4em;">확인</span></v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>

</template>

<script>
import {mapGetters} from 'vuex'
import adminApi from '@/api/admin'

export default {
    data: () => ({
        formData: {
            admin_user_password: '',
        },
        formStatus: {
            admin_user_password: {
                err: false,
                err_msg: '',
            },
        }
    }),
    computed: {
        ...mapGetters({
            adminUserId: 'login/getUserId',
        }),

    },
    mounted() {
        this.$nextTick(() => {
            this.$refs.admin_user_password.focus()
        })
    },
    methods: {
        closePopup() {
            this.$emit('cancel')
        },
        submitPopup() {
            let succ = true
            if (!this.formData.admin_user_password) {
                this.updateErrStatus('admin_user_password', true, '비밀번호를 입력해 주세요')
                succ = false
            }
            if (!succ) {
                return
            }

            let va = this
            adminApi.checkPassword(this.formData, (validFlag) => {
                if (validFlag == 'Y') {
                    // success 
                    va.$emit('submit')
                } else {
                    this.$store.dispatch('openAlert', {message: '비밀번호가 일치하지 않습니다',})
                }
            }, (err) => {
                this.$store.dispatch('openAlert', {message: '비밀번호를 확인할 수 없습니다',})
            })

        },
        updateErrStatus(target, err, errMsg) {
            let status = this.formStatus[target]
            let ref = null

            if (!errMsg) {
                errMsg = ''
            }

            if (status) {
                status.err = err
                status.err_msg = errMsg
                if (err && ref) {
                    ref.focus()
                }
            }
        }
    }
}
</script>
